import React from "react";
import {Button, Card, CardContent, CardHeader, Container, Form, Message} from "semantic-ui-react";
import {PasswordField, UsernameField} from "../form-fields";
import {Link} from "gatsby";
import withTrans from "../../../i18n/withTrans";
import cookie from "react-cookies";
import createbox from "../../../images/login/createbox.png";
import submit from "../../../images/login/submit.png";
import username from "../../../images/login/username.png";
import password from "../../../images/login/password.png";

function SignUpFormView(props) {
    const {
        onUsernameChange,
        onPasswordChange,
        onConfirmPasswordChange,
        loading,
        success,
        errorMsgs,
        onSubmit
    } = props;
    return (
        <Container fluid>
            
                <div style={{
                    padding:"20px",
                    backgroundImage: `url(${createbox})` , backgroundSize:'100% 100%'
                }}>
<Card fluid style={{
    boxShadow: '0 1px 1px 0 #FFF, 0 0 0 1px #FFF',
}}><Form size={"small"} loading={loading} success={success}
error={errorMsgs.length > 0}>
                    <div>
                    {(errorMsgs.length > 0) && (<div style={{color:"red", paddingTop: "10px"}}>{props.t("COMMON.ERROR")}:{errorMsgs.join("\n")}</div>)}                    
                    <div>
                    <div style={{padding:"10px",height:"4rem",
                    border: "0px",
                        backgroundImage: `url(${username})` , backgroundSize:'100% 100%'
                        }}>
                        <UsernameField 
                        style={{width:"80%", height: "90%" ,
                        marginTop: "-3px",
                        marginLeft: "10%",
                        backgroundColor: "#FFF", color:"#333"}}  
                        onChange={onUsernameChange}/>
                        </div>
                        <div style={{padding:"10px",height:"4rem",
                        backgroundImage: `url(${password})` , backgroundSize:'100% 100%'
                        }}>
                        <PasswordField placeholder='密碼'
                        style={{width:"80%", height: "90%" ,
                        marginTop: "-3px",
                        marginLeft: "10%",
                        backgroundColor: "#FFF", color:"#333"}}   
                        onChange={onPasswordChange}/>
                        </div>
                        <div style={{padding:"10px",height:"4rem",
                        backgroundImage: `url(${password})` , backgroundSize:'100% 100%'
                        }}>
                        <PasswordField placeholder='再次輸入密碼'
                        style={{width:"80%", height: "90%" ,
                        marginTop: "-3px",
                        marginLeft: "10%",
                        backgroundColor: "#FFF", color:"#333"}}   
                        onChange={onConfirmPasswordChange}/>
                        </div>
                        <Button
                            style={{
                                height:"4.2rem",
                                background: "transparent",
                                backgroundImage: `url(${submit})` , backgroundSize:'100% 100%'}}
                            fluid size='large' onClick={onSubmit}>
                            &nbsp;
                        </Button>
                    </div>                        
                    </div>
                    </Form>
                </Card>
                </div>
            
        </Container>
    );
}

export default withTrans(SignUpFormView);