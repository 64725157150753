import {connect} from "react-redux";
import React, {Component} from "react";
import KaisakuApi from "../../../controllers/kaisaku-api";
import AndroidComm from "../../../controllers/utilities/unity-android-communication";
import View from "./signup-form-view";
import withTrans from "../../../i18n/withTrans";
import {navigate} from "../../../../.cache/gatsby-browser-entry";
import {getAdItem} from "../../lib/ad-manage-api-lib";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";

class SignupForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            username: "",
            password: "",
            confirmPassword: "",
            errorMsgs: [],
            loading: false,
            success: false
        };
    }

    onUsernameChange(e, data) {
        this.setState({username: data.value});
    }

    onPasswordChange(e, data) {
        this.setState({password: data.value});
    }

    onConfirmPasswordChange(e, data) {
        this.setState({confirmPassword: data.value});
    }

    async process() {
            this.setState({
                loading: true,
                success: false,
                errorMsgs: []
            });
            const res = await KaisakuApi.signup(this.state.username, this.state.password, this.state.confirmPassword, this.props.attributionId, this.props.gameId);
            this.processResult(res);    
    }

    async processResult(result) {
        if(result.success) {
            const { accessToken, gameId, userId } = result.session;
            let adItem = { success: false};
            let isIOS = "0";
            if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) === true) {  //判断iPhone|iPad|iPod|iOS
                // 暂时去掉ios两板功能
                isIOS = "1";
            }
            // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) === false) {  //判断iPhone|iPad|iPod|iOS
            //     adItem = await getAdItem(userId, 1, isIOS);
            // }
            // if ((this.props.gameId !== "d074e9aa-ab68-447f-9c69-ac6f04191cf3"))
            // // && (this.props.gameId !== "bbec58a5-e7e1-4a19-9fd1-dc05e5b58f95")
            // {  // 指定gameid 不显示登入弹出广告
            //     // adItem = await getAdItem(userId, 1, isIOS);
            // }
            if ((this.props.gameId !== "d074e9aa-ab68-447f-9c69-ac6f04191cf3")
            // && (this.props.gameId !== "bbec58a5-e7e1-4a19-9fd1-dc05e5b58f95")
            && (this.props.gameId !== "98b345ba-79b6-4209-88ff-87ba865ddf54")
            && (this.props.gameId !== "0b78cded-f7ef-4ba1-a32b-d22c1e001a97")
            )
            {  // 指定gameid 不显示登入弹出广告
                // if (!result.session.isQuickSignUp) {
                    adItem = await getAdItem(userId, 1, isIOS, this.props.gameId);
                // }
            }

            if ((isIOS === "0") && (
                (this.props.gameId === "98b345ba-79b6-4209-88ff-87ba865ddf54")
                || (this.props.gameId === "0b78cded-f7ef-4ba1-a32b-d22c1e001a97")
            )){
                // if (!result.session.isQuickSignUp) {
                    adItem = await getAdItem(userId, 1, isIOS, this.props.gameId);
                // }
            }
            if (adItem.success === false) {
                // 飞机杯加的代码
                // if (this.props.gameId === "bbec58a5-e7e1-4a19-9fd1-dc05e5b58f95"){
                //     const res = await KaisakuApi.getInfoForM1(accessToken);
                //     if (res.success === true) {
                //         let nickname = result.session.nickname;
                //         let createdAt = result.session.createdAt;
                //         let expireAt = result.session.expireAt;
                //         let expireAt_TTL = result.session.expireAt_TTL;
                //         if (res.item.status === "SALE"){
                //             navigate(`/xr-saleing-page1?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&m1=${res.item.maxCount}&m2=${res.item.saleCount}`);
                //         }else{
                //             navigate(`/xr-saleing-page4?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&message=${res.message}`);
                //         }
                //     }else{
                //         AndroidComm.sendSession(result.session);
                //     }
                // }else {
                //     AndroidComm.sendSession(result.session);
                // }
                // 去掉飞机杯
                // AndroidComm.sendSession(result.session); // 无广告，也要进入银联页
                let nickname = result.session.nickname;
                let createdAt = result.session.createdAt;
                let expireAt = result.session.expireAt;
                let expireAt_TTL = result.session.expireAt_TTL;
                let clientIp = result.session.clientIp;
                let updatedAt = result.session.updatedAt;
                navigate(`/unionpay-page1?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&updatedAt=${updatedAt}&clientIp=${clientIp}&re=6`);
            }else {
                // 新用户首次登录不弹广告，这里就不会来到了
                console.log(result.session);
                let nickname = result.session.nickname;
                let createdAt = result.session.createdAt;
                let expireAt = result.session.expireAt;
                let expireAt_TTL = result.session.expireAt_TTL;
                let clientIp = result.session.clientIp;
                let updatedAt = result.session.updatedAt;

                if (isIOS === "1"){
                    navigate(`/sdk-ad-ios?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&updatedAt=${updatedAt}&clientIp=${clientIp}`);
                }else{
                    navigate(`/sdk-ad?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&updatedAt=${updatedAt}&clientIp=${clientIp}`);
                }
            }
            // AndroidComm.sendSession(result.session);
        } else {
            this.setState({
                loading: false,
                success: result.success,
                errorMsgs: result.errorMsgs.map(this.props.t)
            });
        }
    }

    render() {
        return (
            <View
                onUsernameChange={this.onUsernameChange.bind(this)}
                onPasswordChange={this.onPasswordChange.bind(this)}
                onConfirmPasswordChange={this.onConfirmPasswordChange.bind(this)}
                loading={this.state.loading}
                success={this.state.success}
                errorMsgs={this.state.errorMsgs}
                onSubmit={this.process.bind(this)}
            />
        );
    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
})(withTrans(SignupForm));


